import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// Globally register all `_base`-prefixed components
import "./components/index";

// stylings
import "./assets/styles/main.scss";

// filters
require('./filters/index')


// for notifications
import VuePNotify from "vue-pnotify";
Vue.use(VuePNotify);

// for api calls
import http from "./modules/http.index";
Vue.prototype.$http = http;

import Request from "./modules/requests";
let req = new Request();
Vue.prototype.$req = req;

import { Form, HasError, AlertError } from "vform";
Vue.prototype.$Form = Form;
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.prototype.$event = new Vue();

Vue.prototype.$baseUrl = process.env.VUE_APP_API_BASE_URL

Vue.config.productionTip = false;

// Don't warn about using the dev version of Vue in development.
// Vue.config.productionTip = process.env.NODE_ENV === 'production'
import vuetify from './plugins/vuetify'

import './registerServiceWorker'

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
