<template>
  <!-- Main navbar -->
  <div class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="navbar-brand mr-0">
      <a href="/" class="d-inline-block">
        <img src="/logo.png" alt="proxim" />
      </a>
    </div>

    <div class="d-md-none">
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbar-mobile"
      >
        <i class="icon-tree5"></i>
      </button>
      <button
        @click="_toggleSidebarMobile"
        class="navbar-toggler sidebar-mobile-main-toggle"
        type="button"
      >
        <i class="is-icon-toggle" :class="'icon-paragraph-justify3'"></i>
      </button>
    </div>

    <div class="collapse navbar-collapse" id="navbar-mobile">
      <!-- <ul class="navbar-nav">
        <li class="nav-item" @click="_toggleSidebar">
          <a
            class="
              navbar-nav-link
              sidebar-control sidebar-main-toggle
              d-none d-md-block
            "
          >
            <i class="is-icon-toggle" :class="'icon-paragraph-justify3'"></i>
          </a>
        </li>
      </ul> -->
      <ul class="navbar-nav is-search">
        <form @submit.prevent="doSearch">
          <v-text-field placeholder="Search.." dark v-model="search" hide-details dense>
            <v-icon slot="append" color="green">mdi-magnify</v-icon>
          </v-text-field>
        </form>
      </ul>

      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a href="#" class="navbar-nav-link">
            <i class="icon-bell2"></i>
            <span class="d-md-none ml-2">Notifications</span>
            <span class="badge badge-mark border-white ml-auto ml-md-0"></span>
          </a>
        </li>

        <li class="nav-item dropdown dropdown-user">
          <a
            href="#"
            class="navbar-nav-link d-flex align-items-center dropdown-toggle"
            data-toggle="dropdown"
          >
            <img
              src="../assets/global_assets/images/image.png"
              class="rounded-circle mr-2"
              height="18"
              alt=""
            />
            <span>
              {{ user.first_name || "" }} {{ user.last_name || "" }}
            </span>
          </a>

          <div class="dropdown-menu dropdown-menu-right">
            <router-link :to="{ name: 'Profile' }" class="dropdown-item"
              ><i class="icon-user-plus"></i> My profile</router-link
            >
            <router-link :to="{ name: 'Balance' }" class="dropdown-item"
              ><i class="icon-coins"></i> My balance</router-link
            >
            <router-link :to="{ name: 'Payments' }" class="dropdown-item"
              ><i class="icon-comment-discussion"></i> Payments
              <!-- <span class="badge badge-pill bg-blue ml-auto">58</span> -->
            </router-link>
            <div class="dropdown-divider"></div>
            <!-- <a href="#" class="dropdown-item"
              ><i class="icon-cog5"></i> Account settings</a
            > -->
            <a @click="doLogout" class="dropdown-item"
              ><i class="icon-switch2"></i> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!-- /main navbar -->
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("sidebar", ["showSidebarMobile"]),
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    ...mapActions("auth", ["doLogout"]),
    ...mapMutations("sidebar", ["_toggleSidebar", "_toggleSidebarMobile"]),
    doSearch() {
      document.location.href = `/home/search?search=${this.search}`
      // this.$router.push({name: "Search", query: { search: this.search }})
    },
  },
  mounted() {
    this.search = this.$route.query.search
  }
};
</script>

<style scoped>
.is-icon-toggle {
  transition: 0.3s all ease-in-out;
}
.is-search {
  width: 70%;
}

@media screen and (max-width: 480px) {
  .is-search {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}
</style>
