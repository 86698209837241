import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.store";
import setups from "./setups.store";
import sidebar from "./sidebar.store";
import order from "./order.store";
import orders from "./orders.store";
import messages from "./messages.store";
import writers from "./writers.store";
import editors from "./editors.store";
import payment from "./payment.store";
import sites from "./sites.store";
import clients from "./clients.store";
import clientusers from "./clientusers.store";
// import modules from "./index.module";

// setups
import writerlevels from "./writerlevels.store";
import connections from "./connections.store";
import filetypes from "./filetypes.store";
import styles from "./styles.store";
import academiclevels from "./academiclevels.store";
import documenttypes from "./documenttypes.store";
import languagestyles from "./languagestyles.store";
import spacing from "./spacing.store";
import subjectareas from "./subjectareas.store";
import urgencies from "./urgency.store";
import writerpreferences from "./writerpreferences.store";
import additionalservices from "./additionalservices.store";


Vue.use(Vuex);

// const debug = process.env.NODE_ENV !== 'production'
export default new Vuex.Store({
  state: {
    loading: true,
    search: "",
    dialog: false,
    // for site based setups
    site_id: "",
    // validation
    email_rules: [
      value => !!value || 'Required.',
      value => (value || '').length <= 10 || 'Max 20 characters',
      value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Invalid e-mail.'
      },
    ],
    amount_rules: [
      value => !!value || 'Required.',
      value => !! parseFloat(value) || 'Value must be numeric.',
    ]
  },
  mutations: {
    isLoading: (state, payload) => state.loading = payload
  },
  modules: {
    auth,
    setups,
    sidebar,
    order,
    orders,
    messages,
    writers,
    editors,
    payment,
    sites,
    clients,

    // setups
    writerlevels,
    connections,
    filetypes,
    styles,
    academiclevels,
    documenttypes,
    languagestyles,
    spacing,
    subjectareas,
    urgencies,
    clientusers,
    writerpreferences,
    additionalservices
  },
  // modules: modules,
  strict: false
});
