import { render, staticRenderFns } from "./_base-overlay.vue?vue&type=template&id=5a921132&scoped=true&"
import script from "./_base-overlay.vue?vue&type=script&lang=js&"
export * from "./_base-overlay.vue?vue&type=script&lang=js&"
import style0 from "./_base-overlay.vue?vue&type=style&index=0&id=5a921132&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a921132",
  null
  
)

export default component.exports