export let baseMenuItems = [
    {
        text: 'Dashboard',
        icon: 'icon-home4',
        routeName: 'Dashboard',
    },

    {
        text: 'Messages',
        icon: 'icon-envelope',
        routeName: 'Messages',
    },
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },

];

export let adminMenu = [
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    {
        text: 'Clients',
        icon: 'icon-person',
        routeName: 'Clients',
    },
    {
        text: 'System Users',
        icon: 'icon-person',
        routeName: 'SystemClients',
    },
    {
        text: 'Writers',
        icon: 'icon-person',
        routeName: 'Writers',
    },
    {
        text: 'Editors',
        icon: 'icon-person',
        routeName: 'Editors',
    },
    {
        text: 'Sites',
        icon: 'icon-stack',
        routeName: 'Sites',
    },
    // {
    //     text: 'Online Users',
    //     icon: 'icon-person',
    //     routeName: 'Profile',
    // },

    {
        text: 'Payments',
        icon: 'icon-stack-check',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'icon-stack-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'icon-stack-plus',
        routeName: 'Bonus',
    },

    {
        text: 'Discounts',
        icon: 'icon-stack-minus',
        routeName: 'Discounts',
    },
];

export let setupsMenu = [
    {
        text: 'Connections',
        icon: 'icon-cog3',
        routeName: 'Connections',
    },

    {
        text: 'Writer Levels',
        icon: 'icon-cog3',
        routeName: 'WriterLevels',
    },

    {
        text: 'Writer Preferences',
        icon: 'icon-cog3',
        routeName: 'WriterPreferences',
    },

    {
        text: 'Additional Services',
        icon: 'icon-cog3',
        routeName: 'AdditionalServices',
    },

    {
        text: 'File Types',
        icon: 'icon-cog3',
        routeName: 'FileTypes',
    },
    {
        text: 'Styles',
        icon: 'icon-cog3',
        routeName: 'Styles',
    },
    {
        text: 'Academic Levels',
        icon: 'icon-cog3',
        routeName: 'AcademicLevels',
    },
    {
        text: 'Document Types',
        icon: 'icon-cog3',
        routeName: 'DocumentTypes',
    },

    {
        text: 'Language Styles',
        icon: 'icon-cog3',
        routeName: 'LanguageStyles',
    },

    {
        text: 'Subject Areas',
        icon: 'icon-cog3',
        routeName: 'SubjectAreas',
    },

    {
        text: 'Urgency',
        icon: 'icon-cog3',
        routeName: 'Urgency',
    },

    {
        text: 'Spacing',
        icon: 'icon-cog3',
        routeName: 'Spacing',
    },

    
];


export let reportsMenu = []
export let clientMenu = [
    {
        text: 'Bids',
        icon: 'icon-add-to-list ',
        routeName: 'Bids',
    },
    {
        text: 'Clients',
        icon: 'icon-person',
        routeName: 'Clients',
    },
    {
        text: 'Sites',
        icon: 'icon-stack',
        routeName: 'SystemUserSites',
    },
    // {
    //     text: 'Online Users',
    //     icon: 'icon-person',
    //     routeName: 'Profile',
    // },

    {
        text: 'Payments',
        icon: 'icon-stack-check',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'icon-stack-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'icon-stack-plus',
        routeName: 'Bonus',
    },

    {
        text: 'Discounts',
        icon: 'icon-stack-minus',
        routeName: 'Discounts',
    },


];
export let writerMenu = [
    // {
    //     text: 'My Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    {
        text: 'Payments',
        icon: 'icon-stack-check ',
        routeName: 'Payments',
    },
    // {
    //     text: 'Payment Balance',
    //     icon: 'icon-stack-check',
    //     routeName: 'Balance',
    // },
    {
        text: 'Fines',
        icon: 'icon-stack-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'icon-stack-plus',
        routeName: 'Bonus',
    },

];
export let editorMenu = [
    // {
    //     text: 'Bids',
    //     icon: 'icon-add-to-list ',
    //     routeName: 'Bids',
    // },
    // {
    //     text: 'My Earnings',
    //     icon: 'icon-stack-check ',
    //     routeName: 'Profile'
    // },

    {
        text: 'Writers',
        icon: 'icon-person',
        routeName: 'Writers',
    },

    {
        text: 'Writer Levels',
        icon: 'icon-cog3',
        routeName: 'WriterLevels',
    },
    {
        text: 'Writer Preferences',
        icon: 'icon-cog3',
        routeName: 'WriterPreferences',
    },
    {
        text: 'Additional Services',
        icon: 'icon-cog3',
        routeName: 'AdditionalServices',
    },
    {
        text: 'Payments',
        icon: 'icon-stack-check',
        routeName: 'Payments',
    },
    {
        text: 'Fines',
        icon: 'icon-stack-minus',
        routeName: 'Fines',
    },
    {
        text: 'Bonus',
        icon: 'icon-stack-plus',
        routeName: 'Bonus',
    },

    // {
    //     text: 'Payment Balance',
    //     icon: 'icon-stack-check',
    //     routeName: 'Balance',
    // },
];

export let actionMenuItems = [
    {
        text: 'Place Order',
        icon: 'icon-add',
        routeName: 'PlaceOrder',
    },
];
