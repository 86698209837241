<template>
  <!-- Page header -->
        <div class="page-header page-header-light bg-primary shadow mt-4">
          <div class="page-header-content header-elements-md-inline">
            <div class="page-title d-flex py-2">
              <h4><i class="icon-arrow-left52 mr-2 has-pointer" @click="$router.go(-1)"></i> <span class="font-weight-semibold text-capitalize">{{title || ''}}</span></h4>
            </div>
          </div>
        </div>
        <!-- /page header -->
</template>

<script>

export default {
  name: "Header",
  props: {
    title: {
      type: String,
      required: false,
    }
  }
}
</script>

<style>
  .has-pointer{
    cursor: pointer;
  }
  .page-header {
    border-radius: 16px !important;
}
</style>