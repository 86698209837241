<template>
    <!-- Footer -->
        <div class="navbar navbar-expand-lg navbar-light">
          <div class="text-center d-lg-none ">
            <button type="button" class="navbar-toggler dropdown-toggle" data-toggle="collapse" data-target="#navbar-footer">
              <i class="icon-unfold mr-2"></i>
              Footer
            </button>
          </div>

          <div class="navbar-collapse collapse" id="navbar-footer">
            <span class="navbar-text">
              &copy; 2021
            </span>

          </div>
        </div>
        <!-- /footer -->
</template>


<script>
export default {
    name: "Footer"
}
</script>